.dataPanel {
  border-radius: 4px;
  background-color: #ffffff;
  margin: 1px;
  padding: 16px;
  /* box-shadow: rgba(0, 0, 0, 0.50) 0px 0.0625em 0.0625em,
              rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
              rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset; */
/*
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */

  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset; */
  /* box-shadow: 0px 0px 8px 0.5px #BEBEBE; */
  box-shadow:  0px 1px 4px 0.5px #CCCCCCaa;
}

h4 {
  margin-bottom: 20px;
}