.bg {
    background: linear-gradient(135deg, #05267f, #0a3fd1)
}

.login {
    padding: 20px 20px;
    background-color: #fff;
    width: 764px;
    min-width: 400px;

    border-radius: 10px;
    /* box-shadow: 1px 1px 50px 10px #303030; */
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
}

.login :global(.ant-form-item ) {
    margin-bottom: 14px;
}

.login :global(.ant-form-item-label) {
    padding-bottom: 4px;
}

.login :global(.ant-form-item-label label) {
    font-weight: 400;
}

.login :global(.ant-input-lg) {
    height: 48px;
    font-size: 16px;
    line-height: 30px;
}

.login :global(.ant-input-affix-wrapper > input.ant-input-lg) {
    height: 30.4px;
    line-height: 30px;
}

.login :global(.ant-form-item-explain-error) {
    font-size: 13px;
}

.login :global(.ant-alert-description) {
    font-size: 13px;
    font-weight: 400;
}

.login :global(.ant-btn-primary) {
    /* background-color: #Ff0006;
    border-color: #Ff0006; */
    background-color: #ff0854;
    border-color: #ff0854;
}

.login :global(.ant-btn-primary:hover) {
    background-color: #BF0005;
    border-color: #BF0005;
    /* background-color: #Ff0006;
    border-color: #Ff0006; */
}

.header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

}

.headLogo {
    border-radius: 0px;
    overflow: hidden;
    /* box-shadow: 1px 1px 5px #555; */
    margin-right: 10px;
    margin-top: 10px;
    height: 70px;
    width: 160px;
    background-color: #FFF;
    display: flex;
    align-items: center;

    position: relative;
    top: -15px;
}

.logo {
    width: 170px;
    height: autopx;
    /* padding: -10px; */
    /* margin-right: 20px; */
    object-fit: cover;
    /* margin-top: 10px; */
}

.headerTitleWrapper {
    padding-left: 10px;
}

.headerTitle {
    font-size: 28px;
    font-weight: 500;
    /* font-family: 'Montserrat'; */
    color: #555;
}

.headerSubTitle {
    font-size: 16px;
    font-weight: 300;

}

.content {
    padding: 10px 50px 20px 180px;
}

.version {
    padding-left: 10px;
    width: 784px;

}

@media only screen and (max-width: 800px) {
    .login {
        margin: 0px 20px;
        width: 80%;
        min-width: 380px;
        padding: 20px 10px;
    }

    .version {
        padding-left: 10px;
        width: 80%;
        min-width: 380px;
    }

    .content {
        padding: 10px 10px 10px 10px;
    }

    .header {
        margin-left: 10px;
        margin-right: 10px;
    }

    .headLogo {
        margin-right: 20px;

        position: relative;
        top: -20px;
    }

    .headerTitleWrapper {
        padding-left: 0px;
    }
}

@media only screen and (max-width: 600px) {
    .login {
        width: 80%;
        min-width: 380;
    }

    .version {
        width: 80%;
        min-width: 380px;
    }

    .headLogo {
        width: 100%;
        margin-right: 0px;
        display: flex;
        justify-content: center;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-left: 10px;
        margin-right: 10px;

    }

    .headerTitleWrapper {
        padding-left: 5px;
        margin-top: 20px;
        align-self: flex-start;
    }
}