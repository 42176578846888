 .pageTitle :global(.ant-typography h1), .pageTitle  :global(h1.ant-typography) {
  margin-bottom: 0px;
}

.searchPanel {
  margin: 20px 0px;
  padding: 10px;
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  /* background-color: rgba(204, 204, 204, 0.5); */
  /* background-color: #e5e6ee; */
}

.dataPanel {
  border-radius: 8px;
  background-color: #fff;
  height: calc(100vh - 300px);
  padding: 10px;
}

.dateRange {
  
}

.dateRange :global(.ant-select:not(.ant-select-customize-input)) :global(.ant-select-selector )  {
  
  background-color:transparent;
  border: 1px solid #aaa;

}

.dateRange :global(.ant-select-selection-placeholder), .dateRange :global(.ant-select-arrow) {
  color: #000;
}

.searchText {

}

.searchText :global(.ant-input-affix-wrapper) {
  background-color: #fff;
  border: 0px}

.searchText :global(.ant-input) {
  background-color: #fff;
}

.searchText :global(input) {
  color: #aaa
}

.searchText :global(.ant-btn) {
  box-shadow: none;
  background-color: #fff;
  border-color: transparent;
}
.searchText :global(.ant-input-search-button) {
  height: 30px;
}