.container {
  margin-top: 16px;
  min-width: 650px;
  max-width: 980px;
  /* border: 1px solid red; */
  
}

.userTitleContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid red; */
}
 
.userImage {
  width: 100px;
  height: 100px;
  background-color: rgb(0, 120, 212);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 28px;
}

.displayNameContainer {
  height: 100px;
  width: 550px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1
}

.userTitleActionContainer {
  display: flex;
  justify-content: flex-start;
}

.bg-dark-gray:hover  {
  background-color: red;
}