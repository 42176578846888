.container {
    border-radius: 4px;
    background-color: #ffffff;
    margin: 1px;
    padding: 16px;
    /* box-shadow: rgba(0, 0, 0, 0.50) 0px 0.0625em 0.0625em,
              rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
              rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset; */
    box-shadow: 1px 0px 10px #dddddd;

    width: 50%;
    min-width: 400px;
    max-width: 600px;
}
