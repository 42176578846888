.ant-btn-dangerous.ant-btn-primary {
  background-color: #ff0854;
  border-color:#ff0854;
}

.ant-btn-dangerous.ant-btn-primary:focus,
.ant-btn-dangerous.ant-btn-primary:hover {
  background-color:#BF0005;
  border-color: #BF0005;
}

.outline.ant-btn-lg {
  color: #0737BF;
  border: 1px solid #0737BF;
}



.outline.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link):focus,
.outline.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-link):hover {
  color: #fff;
  background-color: #0737BF;
}

.ant-input-show-count-suffix {
  font-size: 10px;
  color: #bbb;
}

.ant-radio-inner::after {
  margin-top: -5px;
  margin-left: -5px;
}

.ant-form-vertical .ant-form-item-label>label {
  height: 15px;
  font-size: 13px;
  font-weight: 600;
}

.ant-form-large .ant-form-item-label>label {
  height: 15px;
  font-size: 13px;
  font-weight: 600;
}

.ant-descriptions-item-label {
  font-weight: 600;
  font-size: 13px;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
  border-bottom: 1px solid #ddd;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content .ant-steps-item-title {
  font-size: 12px;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  font-size: 12px;
  font-weight: 700;
}
/* Self css */
/* .loading {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
} */

@font-face {
  font-family: 'Anuphan';
  font-weight: 200;
  src: local('Anuphan'), url(./assets/fonts/Anuphan-Thin.otf) format('opentype');
}

@font-face {
  font-family: 'Anuphan';
  font-weight: 300;
  src: local('Anuphan'), url(./assets/fonts/Anuphan-Light.otf) format('opentype');
}

@font-face {
  font-family: 'Anuphan';
  src: local('Anuphan'), url(./assets/fonts/Anuphan-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Anuphan';
  font-weight: 400;
  src: local('Anuphan'), url(./assets/fonts/Anuphan-Regular.otf) format('opentype');
}


@font-face {
  font-family: 'Anuphan';
  font-weight: 500;
  src: local('Anuphan'), url(./assets/fonts/Anuphan-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Anuphan';
  font-weight: 600;
  src: local('Anuphan'), url(./assets/fonts/Anuphan-SemiBold.otf) format('opentype');
}

@font-face {
  font-family: 'Anuphan';
  font-weight: 700;
  src: local('Anuphan'), url(./assets/fonts/Anuphan-Bold.otf) format('opentype');
}
